import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { EnvironmentService } from './services/environment/environment.service';
import { FirebaseService } from './services/firebase/firebase.service';
import { KonamiService, TKonami } from './services/konami/konami.service';
import { ThemeService } from './services/theme/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  public isDev!: boolean;

  private konami$!: Subscription;

  constructor(
    private translateService: TranslateService,
    private environmentService: EnvironmentService,
    private konamiService: KonamiService,
    private firebaseService: FirebaseService,
    private themeService: ThemeService
  ) {
    this.setupTranslations();
    this.setupKonami();
  }

  private setupTranslations() {
    this.translateService.addLangs(['en', 'es', 'pt']);
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }

  private setupKonami() {
    this.isDev = !this.environmentService.production;
    this.konami$ = this.konamiService.watcher$.subscribe((value: TKonami) => {
      this.isDev = value === 'on' ? !this.isDev : this.isDev;
    });
  }

  public ngOnDestroy(): void {
    this.konami$.unsubscribe();
  }
}
