import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { IconComponent } from './components/icon/icon.component';
import { NavPrimaryComponent } from './components/nav/primary-nav/primary.component';
import { LayoutPrimaryComponent } from './layouts/primary/primary.component';
import { AppRoutingModule } from './routes/app-routing.module';
import { TranslateProvider } from './services/factories/translate.factory';
import { LogoComponent } from './components/logo/logo.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutPrimaryComponent,
    NavPrimaryComponent,
    IconComponent,
    LogoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({ loader: TranslateProvider }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
