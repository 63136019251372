import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export type TNavPrimaryLinks = Array<INavPrimaryLinks>;

export interface INavPrimaryLinks {
  link: string;
  text: string;
}

@Component({
  selector: 'nav-primary',
  templateUrl: './primary.component.html',
  styleUrls: ['./primary.component.scss'],
})
export class NavPrimaryComponent {
  public get aboutText(): string {
    return this.translateService.instant('mainNav.links.about');
  }

  public get logosText(): string {
    return this.translateService.instant('mainNav.links.logos');
  }

  public get iconsText(): string {
    return this.translateService.instant('mainNav.links.icons');
  }

  public get blogText(): string {
    return this.translateService.instant('mainNav.links.blog');
  }

  public links!: TNavPrimaryLinks;

  constructor(private translateService: TranslateService) {
    this.links = [
      { link: '/about', text: this.aboutText },
      { link: '/logos', text: this.logosText },
      { link: '/icons', text: this.iconsText },
      { link: '/blog', text: this.blogText },
    ];
  }
}
