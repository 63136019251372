import { Component, Input } from '@angular/core';
import { Icons, TIcon } from './icon-list';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input('icon') public icon!: TIcon;
  @Input('label') public label!: string;
  @Input('size') public size: string = '24';

  public get iconPath(): string {
    return Icons[this.icon];
  }
}
