import { IEnvironment } from './environment.model';

export const environment: IEnvironment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyB9-PYa_YslOSTla2i1h-yuIlWakZ3TKF8',
    authDomain: 'mike-richins.firebaseapp.com',
    databaseURL: 'https://mike-richins-default-rtdb.firebaseio.com',
    projectId: 'mike-richins',
    storageBucket: 'mike-richins.appspot.com',
    messagingSenderId: '380396329669',
    appId: '1:380396329669:web:ac91acef3592aadf206eac',
    measurementId: 'G-4EQYDQ75GE',
  },
};
