<layout-primary>
  <router-outlet></router-outlet>
</layout-primary>

<div
  role="complementary"
  class="is-dev"
  aria-label="The web site is in development"
  *ngIf="isDev"
>
  <span
    class="is-dev__text"
    aria-hidden="true"
    title="The web site is in development"
  >
    <app-icon icon="codeTags"></app-icon>
  </span>
</div>
