import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'layout-primary',
  templateUrl: './primary.component.html',
  styleUrls: ['./primary.component.scss'],
})
export class LayoutPrimaryComponent {
  constructor(private translateService: TranslateService) {}
}
