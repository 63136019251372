<div class="logo">
  <svg
    [attr.width]="size"
    [attr.height]="size"
    [attr.viewBox]="'0 0 ' + defaultSize + ' ' + defaultSize"
    fill="none"
    aria-label="Michael Richins' logo"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      [attr.d]="glassesPath"
      class="logo__glasses"
    />
    <path [attr.d]="hairPath" class="logo__hair" />
  </svg>
  <p class="logo__text" [style]="'--font-size:' + fontSize">
    {{ "primaryLayout.header" | translate }}
  </p>
</div>
