import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum THEME {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = 'system',
}

export enum LOCAL_STORAGE {
  NAME = 'mr__theme',
}

export enum BODY_CLASS {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = '',
}

export type TTheme = THEME.LIGHT | THEME.DARK | THEME.SYSTEM;

export interface IThemes {
  light: THEME.LIGHT;
  dark: THEME.DARK;
  system: THEME.SYSTEM;
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  static get LocalStorageKey() {
    return LOCAL_STORAGE.NAME;
  }

  static get Themes(): IThemes {
    return {
      light: THEME.LIGHT,
      dark: THEME.DARK,
      system: THEME.SYSTEM,
    };
  }

  public theme$!: Observable<TTheme>;

  public get theme(): TTheme {
    return this.themeSubject.getValue();
  }

  public set theme(value: TTheme) {
    this.localStorageValue = value;
    this.bodyClass = value;
    this.themeSubject.next(value);
  }

  private themeSubject!: BehaviorSubject<TTheme>;

  private mediaQueryList!: MediaQueryList;

  private rootElement!: HTMLElement;

  private get localStorageValue(): TTheme {
    return window.localStorage.getItem(ThemeService.LocalStorageKey) as TTheme;
  }

  private set localStorageValue(value: TTheme) {
    window.localStorage.setItem(ThemeService.LocalStorageKey, value);
  }

  private set bodyClass(value: TTheme) {
    switch (value) {
      case THEME.DARK: {
        this.rootElement.classList.remove(BODY_CLASS.LIGHT);
        this.rootElement.classList.add(BODY_CLASS.DARK);
        break;
      }
      case THEME.LIGHT: {
        this.rootElement.classList.remove(BODY_CLASS.DARK);
        this.rootElement.classList.add(BODY_CLASS.LIGHT);
        break;
      }
      case THEME.SYSTEM: {
        this.rootElement.classList.remove(BODY_CLASS.LIGHT);
        this.rootElement.classList.remove(BODY_CLASS.DARK);
        break;
      }
    }
  }

  private get systemTheme(): TTheme {
    const systemIsDarkTheme = this.mediaQueryList.matches;

    return systemIsDarkTheme ? THEME.DARK : THEME.LIGHT;
  }

  constructor() {
    this.initialize();
    this.addListeners();
  }

  public toggleTheme(): TTheme {
    switch (this.theme) {
      case THEME.LIGHT: {
        this.theme = THEME.DARK;
        break;
      }
      case THEME.DARK: {
        this.theme = THEME.SYSTEM;
        break;
      }
      case THEME.SYSTEM:
      default: {
        this.theme = THEME.LIGHT;
        break;
      }
    }

    return this.theme;
  }

  private initialize() {
    this.rootElement = document.documentElement;
    this.mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

    const themeToUse = this.themeToUse();

    this.themeSubject = new BehaviorSubject<TTheme>(themeToUse);
    this.theme$ = this.themeSubject.asObservable();
    this.theme = themeToUse;
  }

  private addListeners() {
    this.mediaQueryList.addEventListener('change', (e: MediaQueryListEvent) => {
      this.theme = e.matches ? THEME.DARK : THEME.LIGHT;
    });
  }

  private themeToUse(): TTheme {
    const systemTheme = this.systemTheme;
    const localStorageTheme = this.localStorageValue;

    return localStorageTheme ? localStorageTheme : systemTheme;
  }
}
