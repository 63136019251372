import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(private environmentService: EnvironmentService) {
    this.initializeApp();
  }

  private initializeApp(): void {
    const app = initializeApp(this.environmentService.firebase);

    getAnalytics(app);
  }
}
