import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IFirebaseConfig } from '../../../environments/environment.model';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  public production$!: Observable<boolean>;
  public firebase$!: Observable<IFirebaseConfig>;

  private productionSubject!: BehaviorSubject<boolean>;
  private firebaseSubject!: BehaviorSubject<IFirebaseConfig>;

  public get production(): boolean {
    return this.productionSubject.getValue();
  }

  public set production(value: boolean) {
    this.productionSubject.next(value);
  }

  public get firebase(): IFirebaseConfig {
    return this.firebaseSubject.getValue();
  }

  public set firebase(value: IFirebaseConfig) {
    this.firebaseSubject.next(value);
  }

  constructor() {
    this.productionSubject = new BehaviorSubject<boolean>(
      environment.production
    );
    this.production$ = this.productionSubject.asObservable();

    this.firebaseSubject = new BehaviorSubject<IFirebaseConfig>(
      environment.firebaseConfig
    );
    this.firebase$ = this.firebaseSubject.asObservable();
  }

  public destroy() {
    this.productionSubject.unsubscribe();
    this.firebaseSubject.unsubscribe();
  }
}
