<nav id="main_nav" class="nav">
  <ul class="nav__list">
    <li class="nav__item" *ngFor="let link of links">
      <a
        class="nav__link"
        [routerLink]="link.link"
        [routerLinkActive]="'nav__link--active'"
        translate
      >
        {{ link.text }}
      </a>
    </li>
  </ul>
</nav>
