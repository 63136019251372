<div class="container">
  <header class="container__header header">
    <app-logo class="header__logo"></app-logo>

    <nav-primary class="header__nav"></nav-primary>
  </header>

  <main>
    <ng-content></ng-content>
  </main>
</div>
