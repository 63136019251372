import { Component, Input } from '@angular/core';
import { LogoPaths } from './logo-paths';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  @Input('size') public size!: number;

  public get defaultSize(): number {
    return this._defaultSize;
  }

  public get glassesPath(): string {
    return this._glassesPath;
  }

  public get hairPath(): string {
    return this._hairPath;
  }

  public get fontSize(): string {
    const multiplier = this._fontSize / this._defaultSize;
    return multiplier * this.size + 'px';
  }

  private _defaultSize: number = 48;
  private _glassesPath: string = LogoPaths.Glasses;
  private _hairPath: string = LogoPaths.Hair;
  private _fontSize: number = 28;

  constructor() {
    this.size = this._defaultSize;
  }
}
