import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';

export type TKonami = 'on' | 'off';

enum KONAMI_KEYS {
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
  B = 'KeyB',
  A = 'KeyA',
}

@Injectable({
  providedIn: 'root',
})
export class KonamiService {
  private static get KonamiSequence(): Array<string> {
    return [
      KONAMI_KEYS.UP,
      KONAMI_KEYS.UP,
      KONAMI_KEYS.DOWN,
      KONAMI_KEYS.DOWN,
      KONAMI_KEYS.LEFT,
      KONAMI_KEYS.RIGHT,
      KONAMI_KEYS.LEFT,
      KONAMI_KEYS.RIGHT,
      KONAMI_KEYS.B,
      KONAMI_KEYS.A,
    ];
  }

  public watcher$!: Observable<TKonami>;

  public get watcher(): TKonami {
    return this.watcherSubject.getValue();
  }
  public set watcher(value: TKonami) {
    this.watcherSubject.next(value);
  }

  private watcherSubject: BehaviorSubject<TKonami> =
    new BehaviorSubject<TKonami>('off');
  private sequencyIndex: number = 0;

  constructor() {
    this.watcher$ = this.watcherSubject.asObservable();

    fromEvent<KeyboardEvent>(window, 'keyup').subscribe((event) => {
      const key: string = event.code;

      if (KonamiService.KonamiSequence.includes(key)) {
        if (key === KonamiService.KonamiSequence[this.sequencyIndex]) {
          this.sequencyIndex++;

          if (this.sequencyIndex === KonamiService.KonamiSequence.length) {
            this.sequencyIndex = 0;
            this.watcher = 'on';
          }
        } else {
          this.sequencyIndex = 0;
          this.watcher = 'off';
        }
      } else {
        this.sequencyIndex = 0;
        this.watcher = 'off';
      }
    });
  }

  public destroy() {
    this.watcherSubject.unsubscribe();
  }
}
